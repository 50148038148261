import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import MANDATORY_Doc_data from "../../Assets/JSON/IQAC/Mandatory.json";
import { Button, Box, Typography } from "@mui/material";
import { Worker, Viewer } from "@react-pdf-viewer/core";

const PDFViewer = () => {
  const [selectedDocData, setSelectedDocData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const filteredData = MANDATORY_Doc_data.documents?.find(
      (docdata) => docdata.id === id
    );
    setSelectedDocData(filteredData);
  }, [id]);

  const handleBackClick = () => {
    navigate("/mandatorydoc");
  };

  const fileUrl = selectedDocData?.fileUrl?.[0];

  return (
    <div className="container py-4">
      <Button variant="contained" onClick={handleBackClick}>
        Go Back
      </Button>
      {selectedDocData && (
        <div>
          <Typography variant="h5" className="text-center">
            {selectedDocData?.filename}
          </Typography>
          <Box height="500px" width="100%">
             <Worker
            workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"
          >
            <Viewer   width="100%"
            height="500px" fileUrl={fileUrl} />
          </Worker>
          </Box>
         
        </div>
      )}
      {!selectedDocData && <p>No data found.</p>}
    </div>
  );
};

export default PDFViewer;