import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button,Box, Typography } from "@mui/material";
import NIRFExData from "../../Assets/JSON/NAACjson/NIRFExtended_Data.json";
import { Worker, Viewer } from "@react-pdf-viewer/core";

const Nirfpdf = () => {
  const [selectedDocData, setSelectedDocData] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    let found = false;
    for (const docdata of NIRFExData.NIRFExtended_Data) {
      for (const key in docdata.URLs) {
        const urlDataArray = docdata.URLs[key];
        const filteredData = urlDataArray.find(
          (urlData) => urlData.id.toString() === id
        );
        if (filteredData) {
          setSelectedDocData(filteredData);
          found = true;
          break;
        }
      }
      if (found) break;
    }

    if (!found) {
      setSelectedDocData(null);
      console.log("No matching data found.");
    }
  }, [id]);

  const handleBackClick = () => {
    navigate("/nirf");
  };

  const fileUrl = selectedDocData?.pathurl;

  return (
    <div className="container py-4">
      <Button variant="contained" onClick={handleBackClick}>
        Go Back
      </Button>
      {selectedDocData ? (
        <div>
          <Typography variant="h5" className="text-center">
            {selectedDocData.filename}
          </Typography>
          <Box textAlign="center" title="Document Viewer"
            width="100%"
            height="500px">
          <Worker
            title="PDF Viewer"
            width="100%"
            height="600px"
            workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js"
          >
            <Viewer fileUrl={fileUrl} />
          </Worker></Box>
        </div>
      ) : (
        <p>No data found.</p>
      )}
    </div>
  );
};

export default Nirfpdf;
