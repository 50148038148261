import { Grid, Breadcrumbs, Typography,CircularProgress } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowIconRight from "../../Assets/Icons/Arrow_Small_Right.svg";
import PDFIcon from "../../Assets/Icons/PDF_Icon.svg";
import XLSXIcon from "../../Assets/Icons/XL_Icon.svg";
import DOCIcon from "../../Assets/Icons/Doc_Icon.svg";
import UnknownFile from "../../Assets/Icons/document_icon.svg";
import NaacExData from "../../Assets/JSON/NAAC/NAACExtended_Data.json";
import "../../Styles/Naac.css";
import { fetchData } from "../../Assets/API/apiservices";
import { useLocation } from "react-router-dom";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  color: theme.palette.mode === "dark" ? "#fff" : "black",
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<img src={ArrowIconRight} alt="Arrow Icon" />}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
    backgroundColor: theme.palette.mode === "dark" ? "#ffff" : "#eeee",
    borderRadius: "50%",
  },
}));

const NACCExtended = () => {
  const [expanded, setExpanded] = useState(0);
  const [headingData, setHeadingData] = useState([]);
  const [subHeadingData, setSubHeadingData] = useState([]);
  const [selectedNaccData, setSelectedNaccData] = useState([]);
  const [selectedsubHeading, setSelectedsubHeading] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const isMounted = useRef(true);
  const navigate = useNavigate();
  const parms = useParams();
  const location = useLocation();
  const NACCExtended_DATA = headingData?.naac_extended_headings;
  const NACCSubheadingData = subHeadingData.NaacExtended_Data;
  const SubheadingData = selectedsubHeading.ExternalID;
  const expand_id = location.state?.expand_id;


  const getHeadingData = async () => {
    try {
      const result = await fetchData("naacdetails/4");
      // setExpanded(result.naac_extended_headings[0].ID)
      return result;
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getSubheadingData = useCallback(async () => {
    try {
      const result = await fetchData(`naacdetails/4?subheading=${SubheadingData}`);
      return result;
    } catch (err) {
      toast.error(err.message);
    }
  }, [SubheadingData]);

  useEffect(() => {
    setExpanded(expand_id)
    handleSubheadingClick ({ExternalID:parms.id, Subheading : parms.name})
    const fetchInitialHeadingData = async () => {
      const heading = await getHeadingData();
      if (heading) {
      setHeadingData(heading);
      setExpanded(expand_id || heading.naac_extended_headings[0].ID);
    }
    };
    fetchInitialHeadingData();
  }, []);

  useEffect(() => {
    const fetchSubHeadingData = async () => {
      if (SubheadingData) {
        try {
          setIsLoading(true);
          const subHeading = await getSubheadingData();
          setTimeout(() => {
            if (subHeading) setSubHeadingData(subHeading);
            setIsLoading(false);
          }, 2000);
        } catch (error) {
          toast.error("Failed to load subheading data.");
          setIsLoading(false);
        }
      }
    };
    fetchSubHeadingData();
  }, [SubheadingData]);

  const handleChange = (index) => (event, newExpanded) => {
    setExpanded(newExpanded ? index : 0);
    if (newExpanded === true) {
      const selectedHeading = NACCExtended_DATA.find((data) => data.ID === index) || null;
      if (selectedHeading && selectedHeading.SubHeading.length > 0) {
        handleSubheadingClick(selectedHeading.SubHeading[0]);
      }
    }
  };
  

  const filterData = useCallback(
    (selected) => {
    console.log("sjdhshd", selected)
      if (!selected || !NACCSubheadingData) return;
      const subheadingData = NACCSubheadingData.find(
        (data) => data.ID === String(selected.ID)
      );
      if (subheadingData && subheadingData.URLs) {
        setSelectedNaccData([subheadingData]);
      } else {
        setSelectedNaccData([]);
      }
      navigate(
        `/NAACExtended/${
          selectedsubHeading.ExternalID
        }/${selectedsubHeading?.Subheading?.replace(/\s/g, "-")}`,
        { state: { expand_id: expanded } }
      );
    },
    [NACCSubheadingData]
  );

  const handleSubheadingClick = async (subheading) => {
    console.log("subheading", subheading);
    if (!subheading) return;
    setSelectedsubHeading(subheading);
    filterData(subheading);
    const subHeadingData = await getSubheadingData(subheading.ExternalID);
    if (subHeadingData) {
      setSubHeadingData(subHeadingData);
      // navigate(
      //   `/NAACExtended/${
      //     subheading.ExternalID
      //   }/${subheading?.Subheading.replace(/\s/g, "-")}`
      // );
    }
  };

  useEffect(() => {
    // if (NACCSubheadingData && NACCSubheadingData.length > 0) {
    //   const isIdValid = !isNaN(parms.id);
  
    //   if (isIdValid && parms.name) {
        const subheadingData = {
          ID: parseInt(parms.id),
          Subheading: parms.name,
        };
        filterData(subheadingData);
    //   } else {
    //     const firstSubheading = NACCExtended_DATA[0]?.SubHeading[0];
    //     filterData(firstSubheading);
    //   }
    // }
  
    return () => {
      isMounted.current = false;
    };
  }, [NACCSubheadingData, filterData, parms.id, parms.name, NACCExtended_DATA]);
  

  const handlePdfClick = (urldata) => {
    if (!urldata.pathurl.trim()) {
      toast.error("There is no file", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    navigate("/extendednaac/" + urldata.ExternalID + "/" + urldata.pathname, {
      state: { selectedDocData: urldata },
    });
  };

  const getFileIcon = (filename) => {
    const ext = filename.split(".").pop().toLowerCase();
    switch (ext) {
      case "pdf":
        return PDFIcon;
      case "xlsx":
        return XLSXIcon;
      case "doc":
      case "docx":
        return DOCIcon;
      default:
        return UnknownFile;
    }
  };

  return (
    <section className="container py-5">
      <Breadcrumbs
        separator="›"
        sx={{ width: "100%", display: "block", marginBottom: "10px" }}
        aria-label="breadcrumb"
      >
        <Link underline="hover" color="white" to="/">
          <b>Home</b>
        </Link>
        <Link underline="hover" to="/NAAC">
          <b>NAAC</b>
        </Link>
        <Typography color="text.primary">
          <b>Supporting Docs</b>
        </Typography>
      </Breadcrumbs>
      <div>
        <Grid container>
          <Grid item xs={12} md={3}>
            {NACCExtended_DATA &&
              NACCExtended_DATA.map((item, index) => {
                console.log(">>>>>expanded", expanded)
                const isExpanded = parseInt(expanded) === parseInt(item.ID);
                return (
                   <Accordion
                    key={index}
                    expanded={isExpanded}
                    onChange={handleChange(item.ID)}
                  >
                    <AccordionSummary
                      style={{
                        backgroundColor: isExpanded
                          ? "rgb(25, 47, 89)"
                          : "#D0D0D0",
                        color: isExpanded ? "white" : "inherit",
                      }}
                    >
                      <Typography>{item.Heading}</Typography>
                    </AccordionSummary>
                    {item?.SubHeading.map((subdata) => (
                      <AccordionDetails
                        key={subdata.ID}
                        onClick={() => handleSubheadingClick(subdata)}
                        style={{
                          backgroundColor:
                          parseInt(selectedsubHeading.ExternalID) === parseInt(subdata.ExternalID)
                              ? "#eee"
                              : "inherit",
                          cursor: "pointer",
                          color:  parseInt(selectedsubHeading.ExternalID) === parseInt(subdata.ExternalID) ? "black" : "inherit",
                        }}
                      >
                        <Typography>{subdata.Subheading}</Typography>
                      </AccordionDetails>
                    ))}
                  </Accordion>
                );
              })}
          </Grid>
          <Grid item xs={12} md={9} className="px-5">
      { isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress />
              </div>
            ) :selectedNaccData.length > 0 &&
        selectedNaccData[0]?.URLs &&
        Object.keys(selectedNaccData[0].URLs).length > 0 ? (
        Object.entries(selectedNaccData[0].URLs).map(([key, levelData]) => (
          <div key={key} style={{ marginBottom: "20px" }}>
            <Typography
              variant="h6"
              style={{
                marginBottom: "10px",
                fontWeight: "600",
                fontSize: "1rem",
                color: "#333",
              }}
            >
              {levelData.Title}
            </Typography>
            <div className="d-flex flex-wrap mb-4">
              {levelData.files && levelData.files.length > 0 ? (
                levelData.files.map((file, index) => (
                  <div
                  key={index}
                  className="col-md-4 mb-4"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: "20px",
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.2s, box-shadow 0.2s",
                    cursor: "pointer",
                    margin: "0 0 0 10px",
                    backgroundColor: "#fff"
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = "scale(1.05)";
                    e.currentTarget.style.boxShadow = "0px 6px 10px rgba(0, 0, 0, 0.2)";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "scale(1)";
                    e.currentTarget.style.boxShadow = "0px 4px 6px rgba(0, 0, 0, 0.1)";
                  }}
                  onClick={() => handlePdfClick(file)}
                >
                  <img
                    src={getFileIcon(file.filename)}
                    alt="File Icon"
                    style={{
                      width: "80px",
                      height: "80px",
                      marginBottom: "10px",
                    }}
                  />
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: "body2",
                      color: "#333",
                      textAlign: "center",
                    }}
                  >
                    {file.filename}
                  </Typography>
                </div>
                
                ))
              ) : (
                <Typography variant="body2">No files available</Typography>
              )}
            </div>
          </div>
        ))
      ) : (
        !isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "200px",
              color: "#666",
            }}
          >
            <Typography variant="body1">No Data Found.</Typography>
          </div>
        )
        
      )}
    </Grid>
        </Grid>
      </div>
      <ToastContainer autoClose={3000} position="top-right" />
    </section>
  );
};

export default NACCExtended;
