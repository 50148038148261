import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Typography,
  Box,
  CircularProgress,
  Card,
  CardContent,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const NAACFileViewer = ({ pathKey = "pathurl", filenameKey = "filename" }) => {
  const [selectedDocData, setSelectedDocData] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state?.selectedDocData) {
      setSelectedDocData(location.state.selectedDocData);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [location.state]);

  const handleBackClick = () => navigate(-1);

  const fileUrl = selectedDocData?.[pathKey];
  const isPdf = fileUrl?.endsWith(".pdf");
  const isImage = /\.(jpeg|jpg|png|gif|bmp)$/i.test(fileUrl);
  const isOffice = /\.(doc|docx|xls|xlsx|ppt|pptx)$/i.test(fileUrl);

  if (loading) {
    return (
      <Box textAlign="center" my={4}>
        <CircularProgress />
        <Typography variant="h6" mt={2}>
          Loading...
        </Typography>
      </Box>
    );
  }

  if (!fileUrl) {
    return (
      <Box textAlign="center" my={6}>
        <Typography variant="h2">No file available.</Typography>
        <Button
          variant="outlined"
          onClick={handleBackClick}
          startIcon={<ArrowBackIcon />}
        >
          Back to List
        </Button>
      </Box>
    );
  }

  return (
    <Box my={4} className="container">
      <Card>
        <CardContent>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={2}
          >
            <Button
              variant="outlined"
              onClick={handleBackClick}
              startIcon={<ArrowBackIcon />}
            >
              Back to List
            </Button>
            <Typography variant="h5" textAlign="center" flexGrow={1}>
              {selectedDocData?.[filenameKey]}
            </Typography>
          </Box>

          {/* Viewer Section */}
          <Box textAlign="center" title="Document Viewer"
            width="100%"
            height="500px">
            {isPdf && (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                <Viewer fileUrl={fileUrl} />
              </Worker>
            )}

            {isImage && (
              <img
                src={fileUrl}
                alt="Document Preview"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            )}

            {isOffice && (
              <iframe
                src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                  fileUrl
                )}`}
                title="Office Document Viewer"
                width="100%"
                height="600px"
                style={{ border: "none" }}
              />
            )}

            {!isPdf && !isImage && !isOffice && (
              <Typography variant="h6" color="error">
                Unsupported file format.
              </Typography>
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default NAACFileViewer;
